<script setup lang="ts">
import { ref, toRaw, toRef } from "vue";
import Wrapper from "@/pages/management/Wrapper.vue";
import ContainerTreeSelect from "@/components/ContainerTreeSelect.vue";

interface ContainerItemV2 {
    id: string;
    name: string;
    asset_count: number;
    parent_container_id: string;
}

type NewContainerlist = { [key: string]: ContainerItemV2 };

const formLoading = ref(false);

const props = defineProps<{
    containers: NewContainerlist;
}>();
const containerItems = toRef(props, "containers");
const errorMessage = ref("");
const userCreated = ref("");

interface ErrorResponse {
    message?: string;
}

const form = ref<{
    first: string;
    last: string;
    email: string;
    role: { name: string; value: string };
    site: { [key: string]: string };
}>({ first: "", last: "", email: "", role: { name: "User", value: "user" }, site: {} });

const resetForm = () => {
    form.value = { first: "", last: "", email: "", role: { name: "User", value: "user" }, site: {} };
};

const options = ref([
    { name: "Administrator", value: "administrator" },
    { name: "Operator", value: "operator" },
    { name: "User", value: "user" },
    { name: "Guest", value: "guest" }
]);

const inviteUser = async (e: Event) => {
    e.preventDefault();

    formLoading.value = true;
    errorMessage.value = "";

    const rawResponse = await fetch("", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            email: form.value.email,
            role: form.value.role.value,
            site: form.value.site || null,
            firstName: form.value.first,
            lastName: form.value.last
        })
    });

    formLoading.value = false;

    if (rawResponse.ok) {
        userCreated.value = `An email has be sent to ${toRaw(form.value.first)} (${toRaw(form.value.email)}) with further instructions.`;
        resetForm();
    } else {
        const got = (await rawResponse.json()) as ErrorResponse;

        if (got?.message) {
            errorMessage.value = got.message;
            if (errorMessage.value?.startsWith("User was created but invitation email failed to send")) {
                resetForm();
            }
        } else {
            errorMessage.value = "failed to send user invitation";
        }
    }
};
</script>

<template>
    <Wrapper>
        <h3 class="mb-6 block">Invite User</h3>
        <form @submit="inviteUser">
            <Message :closable="false" v-if="errorMessage" severity="error" class="mb-4">{{ errorMessage }}</Message>
            <Message :closable="true" v-if="userCreated" severity="info" class="mb-4">{{ userCreated }}</Message>

            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 lg:col-span-2">
                    <div class="text-surface-900 dark:text-surface-0 font-medium text-xl mb-4">Profile</div>
                    <p class="m-0 p-0 text-surface-600 dark:text-surface-200 leading-normal mr-4">Invited user will receive an email with further instructions.</p>
                </div>
                <div class="col-span-12 lg:col-span-10">
                    <div class="grid grid-cols-12 gap-4 formgrid p-fluid w-full">
                        <div class="flex flex-col gap-1 mb-6 col-span-12">
                            <label for="role" class="font-medium text-surface-900 dark:text-surface-0"> Role </label>
                            <SelectButton id="role" v-model="form.role" :options="options" optionLabel="name" aria-labelledby="multiple" class="user-roles" :allow-empty="false" />
                        </div>
                        <div class="flex flex-col gap-1 mb-6 col-span-12 md:col-span-6">
                            <label for="first" class="font-medium text-surface-900 dark:text-surface-0"> First Name </label>
                            <InputText id="first" required v-model="form.first" type="text" placeholder="e.g. John" />
                        </div>
                        <div class="flex flex-col gap-1 mb-6 col-span-12 md:col-span-6">
                            <label for="last" class="font-medium text-surface-900 dark:text-surface-0"> Last </label>
                            <InputText id="last" required v-model="form.last" type="text" placeholder="Citizen" />
                        </div>
                        <div class="flex flex-col gap-1 mb-6 col-span-12 md:col-span-6">
                            <label for="email" class="font-medium text-surface-900 dark:text-surface-0"> Email </label>
                            <InputText id="email" required v-model="form.email" type="email" placeholder="person@example.com" />
                        </div>
                        <div class="flex flex-col gap-1 mb-6 col-span-12 md:col-span-6">
                            <label for="site" class="font-medium text-surface-900 dark:text-surface-0">
                                Site <small class="pl-12" style="font-weight: 100" v-if="form && form.role && form.role.value == 'administrator'"><i>Administrators have access to all sites.</i></small></label
                            >

                            <ContainerTreeSelect :disabled="form && form.role && form.role.value == 'administrator'" :scalar="true" v-model="form.site" :single="true" placeholder="None selected" :containers="containerItems" />
                        </div>
                        <div class="col-span-12">
                            <Button type="submit" :loading="formLoading" label="Send Invitation" class="w-auto mt-4"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </Wrapper>
</template>

<style scoped lang="scss">
.p-selectbutton {
    display: flex;
    justify-content: space-evenly;
    font-size: 1.5rem;

    :deep(.p-togglebutton) {
        padding: 10px;
        flex: 1;
        &.p-togglebutton-checked {
            .p-togglebutton-label {
                color: #000000;
            }
            &::before {
                background: var(--p-primary-300);
            }
        }
    }
}
</style>
