<script setup lang="ts">
import { type Component, computed, defineProps } from "vue";
import LightTowerIcon from "@/assets/icons/light-tower-icon.svg?component";
import GeneratorIcon from "@/assets/icons/generator-icon.svg?component";
import FuelTruckIcon from "@/assets/icons/fuel-truck-icon.svg?component";
import DieselCompressorIcon from "@/assets/icons/diesel-compressor-icon.svg?component";
import ExcavatorIcon from "@/assets/icons/excavator-icon.svg?component";
import LoaderCrawlerIcon from "@/assets/icons/loader-crawler-icon.svg?component";
import LoaderWheelIcon from "@/assets/icons/loader-wheel-icon.svg?component";
import TiltTrayIcon from "@/assets/icons/tilt-tray-icon.svg?component";
import TrafficControlUteIcon from "@/assets/icons/traffic-control-ute-icon.svg?component";
import GarbageTruckIcon from "@/assets/icons/garbage-truck-icon.svg?component";
import MotorbikeIcon from "@/assets/icons/motorbike-icon.svg?component";
import CarIcon from "@/assets/icons/car-icon.svg?component";

interface IconMapping {
    [key: string]: Component;
}

const props = defineProps<{
    deviceType: keyof typeof ICON_MAPPING;
    activeState: boolean;
}>();

const ICON_MAPPING: IconMapping = {
    TYPE_LIGHT_TOWER: LightTowerIcon,
    TYPE_FUEL_TRUCK: FuelTruckIcon,
    TYPE_GENERATOR: GeneratorIcon,
    TYPE_DIESEL_COMPRESSOR: DieselCompressorIcon,
    TYPE_EXCAVATOR: ExcavatorIcon,
    TYPE_LOADER_CRAWLER: LoaderCrawlerIcon,
    TYPE_LOADER_WHEEL: LoaderWheelIcon,
    TYPE_TILT_TRAY: TiltTrayIcon,
    TYPE_TRAFFIC_CONTROL_UTE: TrafficControlUteIcon,
    TYPE_GARBAGE_TRUCK: GarbageTruckIcon,
    TYPE_MOTORBIKE: MotorbikeIcon,
    TYPE_CAR: CarIcon
    // TYPE_OTHER: OtherIcon
} as const;

const iconComponent = computed(() => {
    return ICON_MAPPING[props.deviceType] || null;
});
</script>

<template>
    <component :is="iconComponent" v-if="iconComponent" class="w-10 h-10 fill-zinc-200 stroke-zinc-200" :class="{ '!fill-green-300 !stroke-green-300': props.activeState }" />
</template>
