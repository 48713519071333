import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 22 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"none\" d=\"M1.297 16.96 12.88 8.377 9 3M10.704 16.96V10\"></path><circle cx=\"6\" cy=\"18\" r=\"3\" fill=\"none\"></circle><circle cx=\"15.5\" cy=\"18\" r=\"3\" fill=\"none\"></circle><path fill=\"none\" d=\"M14.555 15.157V6.864L17.699 7c2.3 0 3.3 2 3.3 5v4.633h-2.69\"></path><path d=\"M15 12h5v4h-5z\"></path><path fill=\"none\" d=\"M9 18h2.714\"></path>", 6)
  ])))
}
export default { render: render }