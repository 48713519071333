<script setup lang="ts">
import { toRef } from "vue";
import { Link } from "@inertiajs/vue3";
import { DateTime } from "luxon";
import type { AssetTableItem } from "@/db";
import DeviceIcon from "@/components/DeviceIcon.vue";

const props = defineProps<{
    device: AssetTableItem;
    isFocused: boolean;
    site: string;
}>();

const device = toRef(props, "device");
const isFocused = toRef(props, "isFocused");

// occurring after installation.
const unpoweredState = (d8: string) => {
    const lastUpdated = DateTime.fromISO(d8);

    if (!lastUpdated.isValid) {
        return "Tracker was not receiving power on the last update";
    }

    return `Tracker was not receiving power on the last update\n ${lastUpdated.toLocaleString(DateTime.DATETIME_FULL)}.`;
};

const makeLink = () => {
    return `/map/${device.value.hardware_id}`;
};
</script>

<template>
    <Link preserve-state :href="makeLink()" as="div" :class="{ 'selected-device': isFocused, 'on-rn': device?.flag_running }" class="asset-item flex items-center border-b border-item p-2 pr-4 cursor-pointer transition-colors duration-150">
        <div class="flex-none relative mr-4">
            <DeviceIcon :device-type="device.type" :active-state="device.flag_running ? true : false" />
        </div>

        <div class="grow md:flex">
            <span class="text-surface-100 dark:text-surface-0 text-lg font-semibold block">{{ device.name }}</span>
        </div>
        <div v-tooltip="unpoweredState(null)" v-if="device.obd === false && device.flag_powered === false" class="flex mr-1 font-large">
            <i class="pi pi-exclamation-circle mr-2 off-state"></i>
        </div>
        <div v-tooltip="'Low battery voltage'" class="flex font-large" v-if="device.obd === false && device.flag_powered === true && device.flag_low_voltage">
            <i class="pi pi-bolt mr-2 currentign badvolrtage"></i>
        </div>
        <div class="flex flex-col">
            <p class="flex items-end justify-end text-surface-600 dark:text-surface-400 text-ellipsis overflow-hidden whitespace-nowrap text-sm" style="margin: 0 !important">{{ site }}</p>
            <div class="flex flex-row justify-end">
                <Tag class="flex ml-1" v-if="device.flag_moving" severity="info">Moving</Tag>
            </div>
        </div>
    </Link>
</template>

<style lang="scss">
.list-group-item {
    &:hover {
        cursor: pointer;
        opacity: 85%;
    }
}

.asset-item {
    &:hover {
        background-color: var(--p-primary-700);
    }

    &.selected-device {
        background-color: var(--p-primary-900);
    }

    &.border-item {
        border-color: var(--surface-border);

        &.on-rn {
            border-left-color: var(--p-green-400);
            border-left-width: 10px;
            border-left-style: solid;
        }
    }
}

.currentign {
    font-size: 1.5rem;
}

.badvolrtage {
    color: #ffaf00;
}

.off-state {
    font-size: 1.5rem;
    color: #f00;
}
</style>
