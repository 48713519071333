<script lang="ts" setup>
import { inject, onMounted, onUnmounted, ref } from "vue";
// @ts-ignore
import { type Map as MapboxMap } from "maplibre-gl";
import type GeoJSONSource from "maplibre-gl";
import { mapSymbol } from "@/types";
import { useGeoJSONStore } from "@/store/geojson";
import { geoJsons, PositionEmitter } from "@/plugins/positions";
import { Fea } from "@/utils/geojson-db";
import { router } from "@inertiajs/vue3";
import { type AssetTableItem, db } from "@/db";

// IMEI
const ready = ref(false);
const mounted = ref(false);

const posEmitter = inject(PositionEmitter);

const geoJSONStore = useGeoJSONStore();

/*
el.className = 'marker';
el.style.backgroundImage = `url(${MarkerLT})`;
el.style.backgroundSize = 'cover';
el.style.width = `31px`;
el.style.height = `42px`;
*/

let handler = null;
const map = inject(mapSymbol);

const removeLayer = (theMap: MapboxMap, layer: string) => {
    // was running into a vue unmount issue where layer was already removed on component unmount?
    if (theMap.getLayer(layer)) {
        theMap.removeLayer(layer);
    }
};

const removeSource = (theMap: MapboxMap, layer: string) => {
    // was running into a vue unmount issue where layer was already removed on component unmount?
    if (theMap.getSource(layer)) {
        theMap.removeSource(layer);
    }
};

const removeImage = (theMap: MapboxMap, layer: string) => {
    // was running into a vue unmount issue where layer was already removed on component unmount?
    if (theMap.getImage(layer)) {
        theMap.removeImage(layer);
    }
};

onUnmounted(async () => {
    if (!map.value) {
        return;
    }
    removeLayer(map.value, "assets");
    removeLayer(map.value, "clusters");
    removeLayer(map.value, "cluster-count");
    removeSource(map.value, "all");
    removeImage(map.value, "TYPE_LIGHT_TOWER");
    removeImage(map.value, "TYPE_GENERATOR");
    removeImage(map.value, "TYPE_FUEL_TRUCK");
    removeImage(map.value, "TYPE_DIESEL_COMPRESSOR");
    removeImage(map.value, "TYPE_EXCAVATOR");
    removeImage(map.value, "TYPE_GARBAGE_TRUCK");
    removeImage(map.value, "TYPE_LOADER_CRAWLER");
    removeImage(map.value, "TYPE_LOADER_WHEEL");
    removeImage(map.value, "TYPE_TILT_TRAY");
    removeImage(map.value, "TYPE_TRAFFIC_CONTROL_UTE");
    removeImage(map.value, "TYPE_MOTORBIKE");
    removeImage(map.value, "TYPE_OTHER");
    removeImage(map.value, "TYPE_CAR");
    removeImage(map.value, "TYPE_NONE");

    if (handler !== null) {
        posEmitter.off("*", handler);
    }
});

const start = async () => {
    const theMap = new Map<string, AssetTableItem>();
    for (const asset of await db.assets.toArray()) {
        theMap.set(asset.hardware_id, asset);
    }

    return geoJSONStore.format(theMap);
};

onMounted(async () => {
    await geoJSONStore.waitForReady();

    const markerLightTower = await map.value.loadImage("/images/markers/marker-light-tower.png");
    const markerGenerator = await map.value.loadImage("/images/markers/marker-generator.png");
    const markerFuelTruck = await map.value.loadImage("/images/markers/marker-fuel-truck.png");
    const markerDieselCompressor = await map.value.loadImage("/images/markers/marker-diesel-compressor.png");
    const markerExcavator = await map.value.loadImage("/images/markers/marker-excavator.png");
    const markerGarbageTruck = await map.value.loadImage("/images/markers/marker-garbage-truck.png");
    const markerLoaderCrawler = await map.value.loadImage("/images/markers/marker-loader-crawler.png");
    const markerLoaderWheel = await map.value.loadImage("/images/markers/marker-loader-wheel.png");
    const markerTiltTray = await map.value.loadImage("/images/markers/marker-tilt-tray.png");
    const markerTrafficControlUte = await map.value.loadImage("/images/markers/marker-traffic-control-ute.png");
    const markerMotorbike = await map.value.loadImage("/images/markers/marker-motorbike.png");
    const markerCar = await map.value.loadImage("/images/markers/marker-car.png");
    const imageGeneric = await map.value.loadImage("/images/markers/marker.png");

    map.value.addImage("TYPE_LIGHT_TOWER", markerLightTower.data);
    map.value.addImage("TYPE_GENERATOR", markerGenerator.data);
    map.value.addImage("TYPE_FUEL_TRUCK", markerFuelTruck.data);
    map.value.addImage("TYPE_DIESEL_COMPRESSOR", markerDieselCompressor.data);
    map.value.addImage("TYPE_EXCAVATOR", markerExcavator.data);
    map.value.addImage("TYPE_GARBAGE_TRUCK", markerGarbageTruck.data);
    map.value.addImage("TYPE_LOADER_CRAWLER", markerLoaderCrawler.data);
    map.value.addImage("TYPE_LOADER_WHEEL", markerLoaderWheel.data);
    map.value.addImage("TYPE_TILT_TRAY", markerTiltTray.data);
    map.value.addImage("TYPE_TRAFFIC_CONTROL_UTE", markerTrafficControlUte.data);
    map.value.addImage("TYPE_MOTORBIKE", markerMotorbike.data);

    map.value.addImage("TYPE_OTHER", imageGeneric.data);
    map.value.addImage("TYPE_CAR", markerCar.data);
    map.value.addImage("TYPE_NONE", imageGeneric.data);
    const allSource = {
        type: "geojson",
        data: geoJsons,
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 30
    };
    // @ts-ignore
    map.value.addSource("all", allSource);

    // May break when map is navigated away from too quickly, because of async.
    map.value.addLayer({
        id: "assets",
        type: "symbol",
        source: "all",
        layout: {
            "icon-image": ["get", "type"],
            // required as cooperative for clustering layers
            "icon-overlap": "always",
            "icon-allow-overlap": true,
            "text-allow-overlap": false,
            "icon-anchor": "bottom",
            "icon-size": 0.8,
            // get the year from the source's "year" property
            "text-field": ["get", "name"],
            "text-optional": true,
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-size": 13,
            "text-offset": [0, 0],
            "text-anchor": "top"
        },
        filter: ["all", ["<=", "speed", 22], ["!has", "point_count"]]
    });

    map.value.addLayer({
        id: "clusters",
        type: "circle",
        source: "all",
        filter: ["all", ["has", "point_count"]],
        paint: {
            // Use step expressions (https://maplibre.org/maplibre-style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": ["step", ["get", "point_count"], "#51bbd6", 100, "#f1f075", 750, "#f28cb1"],
            "circle-radius": ["step", ["get", "point_count"], 28, 20, 30, 60, 35, 200, 40, 500, 45]
        }
    });

    map.value.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "all",
        filter: ["all", ["has", "point_count"]],
        paint: {
            "text-color": "#202",
            "text-halo-color": "#fff",
            "text-halo-width": 1
        },
        layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-size": 14,
            "icon-overlap": "always",
            "icon-allow-overlap": true,
            "text-allow-overlap": true
        }
    });

    const posDB = new Fea();

    posDB.init((await start()).features);
    geoJsons.features = posDB.list();

    // @ts-ignore
    map.value.getSource("all").setData(geoJsons);

    handler = async (imei, evt) => {
        let posRef = posDB.get(imei);
        if (!posRef) {
            // TODO: Fix lazy reload fix.
            posDB.init((await start()).features);
            geoJsons.features = posDB.list();
            // @ts-ignore
            const src = map.value.getSource("all") as GeoJSONSource;
            src.setData(geoJsons);
            posRef = posDB.get(imei);
            if (!posRef) {
                console.warn("no position ref");
            }
        }

        if ("longitude" in evt && "latitude" in evt) {
            // @ts-ignore
            posRef.geometry.coordinates = [parseFloat(evt.longitude), parseFloat(evt.latitude)];
        }

        if ("angle" in evt) {
            // @ts-ignore
            posRef.properties.rotation = evt.angle;
        }

        if ("speed" in evt) {
            // @ts-ignore
            posRef.properties.speed = evt.speed;
        }

        // @ts-ignore
        const sauce = map && map.value && (map.value.getSource("all") as GeoJSONSource);
        sauce &&
            sauce.updateData({
                update: [
                    {
                        id: posRef.id,
                        newGeometry: {
                            type: "Point",
                            // @ts-ignore
                            coordinates: posRef.geometry.coordinates
                        },
                        addOrUpdateProperties: [
                            { key: "rotation", value: posRef.properties.rotation },
                            { key: "speed", value: posRef.properties.speed }
                        ]
                    }
                ]
            });
    };

    posEmitter.on("*", handler);

    // inspect a cluster on click
    map.value.on("click", "clusters", async (e) => {
        const features = map.value.queryRenderedFeatures(e.point, {
            layers: ["clusters"]
        });
        const clusterId = features[0].properties.cluster_id;
        // @ts-ignore
        const zoom = await map.value.getSource("all").getClusterExpansionZoom(clusterId);
        map.value.easeTo({
            // @ts-ignore
            center: features[0].geometry.coordinates,
            zoom: zoom + 2.2,
            duration: 500
        });
    });

    map.value.on("mouseenter", "assets", () => {
        map.value.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.value.on("mouseleave", "assets", () => {
        map.value.getCanvas().style.cursor = "";
    });

    map.value.on("click", "assets", async (e) => {
        const features = map.value.queryRenderedFeatures(e.point, {
            layers: ["assets"]
        });
        const hello = features[0];
        router.get(`/map/${encodeURIComponent(hello.properties["serial"])}`, {}, { preserveState: true });
    });

    mounted.value = true;
});
</script>

<template>
    <div class="wrapper">
        <slot v-if="mounted" />
    </div>
</template>
<style lang="scss">
.maplibregl-popup {
    will-change: unset;
}

.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
    border-top-color: #071426;
}
.maplibregl-popup-anchor-top-right .maplibregl-popup-tip {
    border-bottom-color: #071426;
}
.maplibregl-popup-anchor-top .maplibregl-popup-tip {
    border-bottom-color: #071426;
}
.maplibregl-popup-anchor-top-left .maplibregl-popup-tip {
    border-bottom-color: #071426;
}
.maplibregl-popup-anchor-left .maplibregl-popup-tip {
    border-right-color: #071426;
}
.maplibregl-popup-anchor-right .maplibregl-popup-tip {
    border-left-color: #071426;
}

.maplibregl-popup-content {
    font-size: 1em;
    background: #071426;
    color: #fff !important;
    border-radius: 10px;
}

.maplibregl-popup-close-button {
    @apply px-2;
    @apply py-0.5;
    @apply text-2xl/6;
}
</style>
<style lang="scss" scoped>
.marker-asset-none {
    background-image: url("/images/markers/marker-lt.png");
    background-size: cover;
    width: 30px;
    height: 41px;
}

.marker-asset-light_tower {
    background-image: url("/images/markers/marker-lt.png");
    background-size: cover;
    width: 30px;
    height: 41px;
}

.marker-asset-fuel_truck {
    background-image: url("/images/markers/marker-truck.png");
    background-size: cover;
    width: 30px;
    height: 41px;
}

.marker-asset-generator {
    background-image: url("/images/markers/marker-generator.png");
    background-size: cover;
    width: 30px;
    height: 41px;
}
</style>
