import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 22 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      d: "M15 14H1M19 18.5a2.5 2.5 0 0 1-5 0 2.5 2.5 0 0 1 5 0M8 18.5a2.5 2.5 0 0 1-5 0 2.5 2.5 0 0 1 5 0M14.05 18h-6.1"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      d: "M3.05 18H1v-4M15 8h3l3 4.546v4.545L20 18h-1.05"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      d: "M20 14h-5V8M13 14V2M10 14V9M12 5V2M2 14l2-4 2 4z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      d: "m5 14 2-4 2 4z"
    }, null, -1)
  ])))
}
export default { render: render }