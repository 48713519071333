import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 22 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"none\" d=\"M5 17h12\"></path><path fill=\"none\" d=\"M5 21h11\" data-name=\"Path_32\"></path><circle cx=\"16.01\" cy=\"18.96\" r=\"2\" fill=\"none\"></circle><circle cx=\"5\" cy=\"19\" r=\"2\" fill=\"none\"></circle><path d=\"m20.526 8.703-1.002-3.637-2.645 2.685z\"></path><path fill=\"none\" d=\"m2 11 1-7h7M10 12 8 4M5 10V8M2 14h1\"></path><path fill=\"none\" d=\"M5 17H1v-6h6.154l2.461 1.75H17V18M15 12l-2-7 5-4 1 4\"></path>", 7)
  ])))
}
export default { render: render }