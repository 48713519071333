import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "data-name": "Layer 2",
  viewBox: "0 0 21.01 21.96"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g class=\"fill-none\" data-name=\"Layer 1\"><g class=\"stroke-none\"><path d=\"M21.01 15.96v2c0 .55-.44 1-1 1h-1a2 2 0 1 0-4 0h-6v-5c0-1.1.9-2 2-2h6c2.21 0 4 1.79 4 4\"></path><circle cx=\"2.5\" cy=\"2.5\" r=\"1.5\"></circle><circle cx=\"2.5\" cy=\"7.5\" r=\"1.5\"></circle><circle cx=\"11.5\" cy=\"7.5\" r=\"1.5\"></circle><circle cx=\"11.5\" cy=\"2.5\" r=\"1.5\"></circle><circle cx=\"17.01\" cy=\"18.96\" r=\"2\"></circle></g><g class=\"fill-none\"><path d=\"M3 5h8\" data-name=\"Path 28\"></path><path d=\"M3.01 18.86h12\" data-name=\"Path 32\"></path><path d=\"M7 5v14\" data-name=\"Path 29\"></path></g></g>", 1)
  ])))
}
export default { render: render }