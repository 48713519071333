import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 22 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M21.01 15.96v2c0 .55-.44 1-1 1h-1a2 2 0 1 0-4 0h-6v-5c0-1.1.9-2 2-2h6c2.21 0 4 1.79 4 4" }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      d: "M3.015 18.862h12"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "17.01",
      cy: "18.96",
      r: "2",
      fill: "none"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      d: "M5 16v4.815"
    }, null, -1)
  ])))
}
export default { render: render }