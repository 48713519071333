<script setup lang="ts">
import { getCurrentInstance, inject, markRaw, onUnmounted, onMounted, provide, ref, shallowRef, toRef, watch, nextTick, watchEffect } from "vue";
import { type LngLatBoundsLike, Map as MapboxMap, NavigationControl, Popup } from "maplibre-gl";
import type { MapController } from "@/types";
import { $MapInstance, isLoadedSymbol, $Popup, mapSymbol, isInitializedSymbol } from "@/types";
import { injectionKey } from "@/plugins/mapregistry";
import { until } from "@vueuse/core";
import { useBreakpoints } from "@vueuse/core";
import { breakpointsConfig } from "@/utils/breakpoints";

const breakpoints = useBreakpoints(breakpointsConfig);

const mapRegistry = inject(injectionKey);
const mapContainer = shallowRef<HTMLDivElement | null>(null);
const maplibre = shallowRef<MapboxMap>();

const props = defineProps<{
    classes?: string;
    bounds?: { boundary: LngLatBoundsLike };
    tilingKey: string;
}>();

const bounds = toRef(props, "bounds");
const isLoaded = ref(false);
const isInitialized = ref(false);
const popup = new Popup({ offset: 40, maxWidth: "none" });

provide(isLoadedSymbol, isLoaded);
provide($Popup, popup);
provide(mapSymbol, maplibre);
provide(isInitializedSymbol, isInitialized);

watch(
    bounds,
    async () => {
        await until(isLoaded).toBe(true);

        // @ts-ignore
        let shouldApply = bounds.value.boundary.filter((r) => r > 0).length > 0;
        // @ts-ignore
        while (!shouldApply || bounds.value.boundary == [0, 0, 0, 0]) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            // @ts-ignore
            shouldApply = bounds.value.boundary.filter((r) => r > 0).length > 0;
        }

        maplibre.value?.fitBounds(bounds.value.boundary, {
            maxDuration: 1,
            maxZoom: 14
        });
    },
    { immediate: true }
);

onUnmounted(() => {
    maplibre.value?.remove();
});

watchEffect(() => {
    if (maplibre.value) {
        const isDesktop = breakpoints.greater("lg").value;

        if (isDesktop) {
            maplibre.value.setPadding({ top: 0, bottom: 0, right: 0, left: 800 });
            return;
        }

        maplibre.value.setPadding({ top: 0, bottom: 0, right: 0, left: 0 });
    }
});

// @ts-ignore
const theMapInstance = mapRegistry.register(markRaw(getCurrentInstance()));

provide($MapInstance, {
    addMarker: (marker) => {
        marker.addTo(maplibre.value);
    }
} as MapController);

onMounted(() => {
    theMapInstance.isMounted = true;
    isInitialized.value = true;

    maplibre.value = markRaw(
        new MapboxMap({
            container: mapContainer.value,
            style: `https://api.maptiler.com/maps/streets/style.json?key=${props.tilingKey}`,
            center: [0, 0]
        })
    );

    maplibre.value.addControl(new NavigationControl({ showCompass: false }), "bottom-right");

    // @ts-ignore
    theMapInstance.map = maplibre;

    maplibre.value.on("load", (ev) => {
        document.querySelector(".maplibregl-compact-show").classList.remove("maplibregl-compact-show");
        nextTick(() => {
            maplibre.value.resize();

            isLoaded.value = true;
            theMapInstance.isLoaded = true;
        });
    });

    maplibre.value.getCanvas().addEventListener("webglcontextlost", () => {
        if (maplibre.value) {
            // Tear down
            // Re-initialize
        }
    });
});
</script>

<template>
    <div id="map" :class="classes" ref="mapContainer"></div>
    <slot v-if="isLoaded" />
</template>

<style>
@import "maplibre-gl/dist/maplibre-gl.css";
</style>
