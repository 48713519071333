<script setup>
import { inject } from "vue";
import { Config } from "@/utils/build";
import { Link } from "@inertiajs/vue3";

const configuration = inject(Config);
</script>

<template>
    <Link href="/map" class="app-logo">
        <img class="" :src="configuration.LogoURL" :alt="configuration.PlatformProvider" />
    </Link>
</template>

<style>
.app-logo img {
    width: 100%;
    max-width: 135px;
    max-height: 24px;
}
</style>
