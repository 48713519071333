import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 22 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"none\" d=\"M7 18.862h5M2 16.125V10h5l2 1.75h6V17\"></path><circle cx=\"14.01\" cy=\"18.96\" r=\"2\" fill=\"none\"></circle><path fill=\"none\" d=\"M7 18c0 1.665-1.335 3-3 3-1.65 0-3-1.335-3-3 0-1.65 1.35-3 3-3a3 3 0 0 1 3 3\"></path><path d=\"m20.526 17.703-1.002-3.637-2.645 2.685z\"></path><path fill=\"none\" d=\"M13 11V8.143L14 7M3 10l1-7h7M11 11 9 3M6 9V7\"></path>", 5)
  ])))
}
export default { render: render }