<script setup lang="ts">
import { ref, toRef, computed, watch, inject, nextTick } from "vue";
import type { Ref } from "vue";
import type { CalibratedVoltage, CalibratedVoltages, ServicingResponse } from "@/types";
import { useTimeAgo } from "@vueuse/core";
import Tag from "primevue/tag";
import { DateTime, Duration, Interval } from "luxon";
import PanelDetails from "@/components/PanelDetails.vue";
import { Link } from "@inertiajs/vue3";
import { AssetTypeMapping } from "@/db";
import type { AssetTableItem, Coordinator } from "@/db";
import { useLatestPositionsStore } from "@/store/positions";
import AssetJournies from "@/components/AssetJournies.vue";
import type { TripResponse } from "@/utils/trip";
import { Config } from "@/utils/build";

const { PlatformProvider } = inject(Config);

interface ContainerItemV2 {
    id: string;
    name: string;
    parent_container_id?: string;
    asset_count: number;
    trip?: TripResponse;
}
type NewContainerlist = { [kgey: string]: ContainerItemV2 };

const posStore = useLatestPositionsStore();

const props = defineProps<{
    class?: string;
    selectedAsset: AssetTableItem;
    cb?: CalibratedVoltages;
    containers: NewContainerlist;
    selectedTrip?: TripResponse;
}>();

const classes = toRef(props, "class");
const currentAsset = toRef(props, "selectedAsset");
const containers = toRef(props, "containers");
const selectedTrip = toRef(props, "selectedTrip");
let selectedDevice: Ref<Coordinator> = posStore.allPositions.get(currentAsset.value?.imei) ?? ref({} as Coordinator);

const lastUpdateInterval = ref<Interval>();

const setLastDataAge = (lastUpdatedAt: Date) => {
    const twoWeeksAgo = DateTime.now();
    const lastUpdated = DateTime.fromJSDate(lastUpdatedAt);

    lastUpdateInterval.value = Interval.fromDateTimes(lastUpdated, twoWeeksAgo);
};

let lastUpdatedAt = useTimeAgo(selectedDevice?.value?.timestamp);

setLastDataAge(selectedDevice?.value?.timestamp);

const batteryVoltage = (v: number) => {
    if (!isNaN(v)) {
        return (v / 1000).toFixed(2);
    }

    return 0;
};

const maintenanceInfo = ref<ServicingResponse>();

const lastDataReceivedAt = useTimeAgo(() => selectedDevice.value.timestamp);

const hasBeenSeen = computed(() => {
    return selectedDevice?.value?.id !== undefined;
});

const utilsation = ref<UtilResponse | null>(null);

const yesterdayMinutes = ref<Duration | null>();
const lastRun = ref(null);

interface Time {
    seconds: number;
}

interface Trip {
    started_at: Time;
    finished_at: Time;
    minutes: number;
    finalised: boolean;
}
interface UtilResponse {
    runtime_minutes_last_day: number;
    month_to_date_percentage: number;
    month_to_date_mins: number;

    // unix timestamp.
    previous_starting_from: Time;
    previous_starting_until: Time;
    previous_minutes_total: number;
    trips: Trip[];
}

interface RecentTrip {
    started_at: DateTime;
    finished_at: DateTime;
    minutes_total: number;
}

fetch("/servicing", {
    method: "POST",
    credentials: "include",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        imei: selectedDevice?.value.id,
        asset_id: currentAsset?.value.id
    })
})
    .then((response) => response.json() as any as ServicingResponse)
    .then((data) => {
        maintenanceInfo.value = data;
        nextTick(() => {
            //popup.setHTML(pls.value.innerHTML)
        });
    })
    .catch((reason) => {
        maintenanceInfo.value = null;
        nextTick(() => {});
    });

watch(
    selectedDevice,
    () => {
        if (!selectedDevice.value) {
            return;
        }

        //	timestamp.value = selectedDevice.value.timestamp
        fetch("/util", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                imei: selectedDevice.value.id
            })
        })
            .then((response) => response.json())
            .then((data) => {
                utilsation.value = data;
                // @ts-ignore

                const dur = Duration.fromObject({ minutes: utilsation?.value?.runtime_minutes_last_day });
                yesterdayMinutes.value = dur;

                //const dur2 = Duration.fromObject({ minutes: utilsation?.value?.prev });
                lastRun.value = useTimeAgo(new Date(utilsation?.value?.previous_starting_from?.seconds * 1000)).value;
            });
    },
    { immediate: true }
);

const genVoltageRange = (cv: CalibratedVoltage) => {
    return `${(cv.lower / 1000).toFixed(2)} V to ${(cv.upper / 1000).toFixed(2)} V`;
};

const containerLocation = computed(() => {
    let currentContainer = containers.value[currentAsset?.value?.container_id ?? ""];
    if (!currentContainer) {
        return "";
    }

    let stringParts = [];

    while (currentContainer) {
        stringParts.push(currentContainer.name);

        currentContainer = containers.value[currentContainer.parent_container_id];
    }

    return stringParts.reverse().join(" > ");
});
const display = ref(false);
const open = () => {
    display.value = true;
};

const close = () => {
    display.value = false;
};
const inputNumberValue = ref(null);
let urlParams = new URLSearchParams(window.location.search);
const allowedMaintenance = urlParams.has("preview");

const linkToTrackerList = (ttSerial: string) => {
    return `/management/trackers?keyword=${ttSerial}`;
};

const unpoweredState = (d8: Date) => {
    const lastUpdated = DateTime.fromJSDate(d8);

    if (!lastUpdated.isValid) {
        return "Tracker was not receiving power on the last update";
    }

    return `Tracker was not receiving power on the last update\n ${lastUpdated.toLocaleString(DateTime.DATETIME_FULL)}.`;
};
</script>

<template>
    <Dialog header="Record serviced asset" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
        <p class="leading-normal m-0">Provide the time of service and engine hours at the time of servicing.</p>
        <br />
        <div class="p-fluid formgrid grid grid-cols-12 gap-4">
            <div class="field col-span-12">
                <label for="address">Notes</label>
                <Textarea id="address" rows="4" />
            </div>
            <div class="field col-span-12 md:col-span-6">
                <label for="state">Engine Hours</label>
                <InputNumber v-model="inputNumberValue" showButtons mode="decimal"></InputNumber>
            </div>
            <div class="field col-span-12 md:col-span-6">
                <label for="zip">Date</label>
                <InputText mode="decimal" />
            </div>
        </div>

        <template #footer>
            <Button label="Record Service" @click="close" icon="pi pi-check" outlined />
        </template>
    </Dialog>

    <div id="selected-device-bar" :class="classes" class="">
        <div class="p-6">
            <div>
                <div class="flex">
                    <div class="grow flex flex-col">
                        <div class="font-medium text-xl text-surface-100 dark:text-surface-400 pr-16">{{ AssetTypeMapping?.[currentAsset?.type] }}</div>
                        <div class="font-medium text-3xl text-surface-200 dark:text-surface-0 pr-16">{{ currentAsset?.name }}</div>
                    </div>
                    <div class="flex-none flex items-start">
                        <slot />
                    </div>
                </div>

                <Message v-if="currentAsset.obd === false && selectedDevice?.voltage == 0" icon="pi pi-exclamation-triangle" class="mt-4" severity="warn">{{ unpoweredState(selectedDevice?.timestamp) }}</Message>
                <Message v-if="!hasBeenSeen" severity="info" class="mt-4" icon="pi pi-info-circle">Tracker has not come online yet.</Message>

                <div class="flex items-center flex-wrap mb-1">
                    <Link as="a" :href="linkToTrackerList(currentAsset?.hardware_id)" class="mr-8 flex items-center mt-4 no-underline text-surface-700 dark:text-surface-300 hover:underline">
                        <i class="pi pi-globe mr-2"></i>
                        <span>{{ currentAsset?.hardware_id }}</span>
                    </Link>
                </div>

                <div class="flex items-center text-surface-300 dark:text-surface-300 flex-wrap my-1">
                    <div class="mr-8 flex items-center mt-1">
                        <i class="pi pi-box mr-2"></i>
                        <span>{{ containerLocation }}</span>
                    </div>
                </div>
            </div>

            <div v-if="hasBeenSeen === true && selectedDevice?.voltage != 0 && lastUpdateInterval && lastUpdateInterval?.length('days') > 10">
                <Message :closable="false" class="mt-4" icon="pi pi-info-circle" severity="info">Information is {{ lastUpdateInterval.length("days").toFixed(0) }} days old. Device may be offline or have no network coverage.</Message>
            </div>

            <ul class="list-none p-0 m-0 mt-4">
                <li v-tooltip="lastUpdatedAt" v-if="cb.current?.median" class="p-3 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-surface-700">
                    <div class="flex items-center">
                        <i class="pi pi-play text-2xl mr-4"></i>
                        <span class="ext-lg font-medium">Ignition</span>
                    </div>
                    <span class="text-lg font-bold">{{ currentAsset.flag_running === true ? "On" : "Off" }}</span>
                </li>

                <li v-if="hasBeenSeen" v-tooltip="lastUpdatedAt" class="p-3 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-surface-700">
                    <div class="flex items-center">
                        <i class="pi pi-bolt text-2xl mr-4"></i>
                        <span class="ext-lg font-medium">Voltage</span>
                    </div>
                    <span class="text-lg font-bold">{{ batteryVoltage(selectedDevice.voltage) }} V</span>
                </li>

                <PanelDetails v-if="cb.current" icon="pi-bolt" class="voltage-panel" name="Typical Voltage" :value="genVoltageRange(cb.current)">
                    <DataTable ref="dt" :value="cb.all" dataKey="at_time.seconds" :rows="5">
                        <template #empty> No voltage calibration found.</template>
                        <Column header="Date" class="whitespace-nowrap w-4/12">
                            <template #body="{ data }">
                                {{ DateTime.fromSeconds(data.at_time.seconds).toLocaleString(DateTime.DATE_SHORT) }}
                            </template>
                        </Column>

                        <Column header="Threshold" class="whitespace-nowrap w-4/12">
                            <template #body="{ data }"> {{ data.median / 1000 }} V </template>
                        </Column>
                        <Column header="Upper" class="whitespace-nowrap w-4/12">
                            <template #body="{ data }"> {{ data.upper / 1000 }} V </template>
                        </Column>

                        <Column header="Lower" class="whitespace-nowrap w-4/12">
                            <template #body="{ data }"> {{ data.lower / 1000 }} V </template>
                        </Column>
                        <Column v-if="false" field="inventoryStatus" header="Status" class="whitespace-nowrap w-4/12 text-right">
                            <template #body="{ data }">
                                <Tag v-if="cb.current.at_time.seconds == data.at_time.seconds" value="CURRENT" severity="success"></Tag>
                                <Tag v-else value="OLD" severity="warning"></Tag>
                            </template>
                        </Column>
                    </DataTable>
                </PanelDetails>
            </ul>

            <div v-if="allowedMaintenance">
                <span v-if="hasBeenSeen" class="text-surface-100 dark:text-surface-0 text-2xl mb-6 font-medium">Maintenance</span>
                <p v-if="hasBeenSeen" class="font-small">Service interval every 250 hours</p>
                <ul class="list-none p-0 m-0">
                    <li v-if="hasBeenSeen" class="p-4 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-purple-900">
                        <div class="flex items-center">
                            <i class="pi pi-history text-2xl mr-4"></i>
                            <span class="ext-lg font-medium">Engine Hours</span>
                        </div>
                        <span class="text-lg font-bold">2,844 Hours</span>
                    </li>

                    <li v-if="hasBeenSeen" class="p-4 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-purple-900">
                        <div class="flex items-center">
                            <i class="pi pi-bell text-2xl mr-4"></i>
                            <span class="ext-lg font-medium">Next Service</span>
                        </div>
                        <span class="text-lg font-bold">in 200 hours</span>
                    </li>

                    <li v-if="hasBeenSeen" class="p-4 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-purple-900">
                        <div class="flex items-center">
                            <i class="pi pi-calendar-plus text-2xl mr-4"></i>
                            <span class="ext-lg font-medium">Last Serviced</span>
                        </div>
                        <span class="text-lg font-bold">9th of August, 2023</span>
                    </li>
                    <Button v-if="hasBeenSeen" label="Log Service" class="w-full mb-6" type="button" @click="open"></Button>
                </ul>
            </div>

            <Panel toggleable :collapsed="true" v-if="maintenanceInfo?.last_meter_read">
                <template #header>
                    <span class="text-surface-100 dark:text-surface-0 text-2xl px-4 py-3 text-center font-medium">Maintenance</span>
                </template>

                <!--<p  v-if="hasBeenSeen" class="font-small">Service interval every 250 hours</p>-->
                <ul class="list-none p-0 m-0">
                    <li v-if="maintenanceInfo?.last_meter_read" class="p-4 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-surface-700">
                        <div class="flex items-center">
                            <i class="pi pi-history mr-4"></i>
                            <span class="ext-lg font-medium">Estimated Meter Read</span>
                        </div>
                        <span class="text-lg font-bold">{{ maintenanceInfo?.estimated_service_read }} hrs</span>
                    </li>
                    <!--<span class="text-lg font-bold"><pre>{{ maintenanceInfo }}</pre></span>
-->

                    <li v-if="maintenanceInfo?.last_meter_read" class="p-4 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-surface-700">
                        <div class="flex items-center">
                            <i class="pi pi-calendar-plus text-2xl mr-4"></i>
                            <span class="ext-lg font-medium">Last Meter Reading</span>
                        </div>
                        <span class="text-lg font-bold">{{ maintenanceInfo?.last_meter_read }}h</span>
                        <span v-if="maintenanceInfo?.last_meter_read_at?.seconds"> {{ DateTime.fromSeconds(maintenanceInfo?.last_meter_read_at?.seconds).toLocaleString(DateTime.DATE_SHORT) }}</span>
                    </li>

                    <!--
					<li v-if="hasBeenSeen" class="p-3 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-surface-700">
						<div class="flex items-center">
							<i class="pi pi-calendar-plus text-2xl mr-4"></i>
							<span class="ext-lg font-medium">Last Serviced</span>
						</div>
						<span class="text-lg font-bold">Not Logged</span>
					</li>

					<li v-if="hasBeenSeen" class="p-3 mb-4 flex items-center justify-between rounded-border bg-purple-50 text-surface-700">
						<div class="flex items-center">
							<i class="pi pi-bell text-2xl mr-4"></i>
							<span class="ext-lg font-medium">Next Service</span>
						</div>
						<span class="text-lg font-bold">Not Logged</span>
					</li>
					-->
                    <!--
					<Button v-if="hasBeenSeen" label="Log Service" class="w-full mb-6" type="button" @click="open"></Button>
					-->
                </ul>
            </Panel>

            <Panel toggleable class="mt-4" v-if="maintenanceInfo?.hired_contract_number">
                <template #header>
                    <span class="text-surface-100 dark:text-surface-0 text-2xl px-4 py-3 text-center font-medium">Hire Terms</span>
                </template>

                <div class="flex flex-wrap mb-1">
                    <div class="items-center justify-center pr-4 pb-4 rounded-border" style="min-width: 50%" v-tooltip="maintenanceInfo?.hired_customer_account">
                        <h5 class="text-surface-400 dark:text-surface-400 font-light text-lg m-0 p-0">Customer name</h5>
                        <h4 class="p-0 pt-1 m-0 font-light text-xl">{{ maintenanceInfo?.hired_customer_name }}</h4>
                    </div>
                    <!--
					<div class="items-center justify-center pr-4 pb-4 rounded-border" style="min-width: 45%;">
						<h5 class="text-surface-400 dark:text-surface-400 font-light m-0 p-0">Return date</h5>
						<h3 class="p-0 pt-1 m-0 font-light">{{ maintenanceInfo?.hired_return_date.seconds }}</h3>
					</div>
					-->
                    <div class="items-center justify-center pr-4 pb-4 rounded-border" style="min-width: 45%">
                        <h5 class="text-surface-400 dark:text-surface-400 text-lg font-light m-0 p-0">Rate</h5>
                        <h4 class="p-0 pt-1 m-0 font-light text-xl">
                            ${{ maintenanceInfo?.hired_charge_rate }}<span style="font-size: 12px">/{{ maintenanceInfo?.hired_charge_period }}</span>
                        </h4>
                    </div>
                    <div class="items-center justify-center pr-4 pb-4 rounded-border" style="min-width: 45%">
                        <h5 class="text-surface-400 dark:text-surface-400 text-lg font-light m-0 p-0">Hire contract</h5>
                        <h4 class="p-0 pt-1 m-0 font-light text-xl">{{ maintenanceInfo?.hired_contract_number }}</h4>
                    </div>
                </div>
            </Panel>

            <Panel toggleable v-if="hasBeenSeen" class="mt-4">
                <template #header>
                    <span class="text-surface-100 dark:text-surface-0 text-2xl px-4 py-3 text-center font-medium">Utilisation</span>
                </template>

                <div v-if="hasBeenSeen && !cb.current">
                    <Message severity="info" icon="pi pi-info-circle">{{ PlatformProvider }} is still gathering data on {{ currentAsset?.name }} for Utilisation data. No action is required.</Message>
                </div>
                <ul class="list-none p-0 m-0">
                    <!--<li class="p-4 mb-4 flex items-center justify-between rounded-border bg-teal-50 text-teal-900">
	<div class="flex items-center">
		<i class="pi pi-play text-2xl mr-4"></i>
		<span class="ext-lg font-medium">Current Run</span>
	</div>
	<span class="text-lg font-bold">5H 11M</span>
	</li>-->

                    <li v-if="hasBeenSeen && cb.current" :title="utilsation?.month_to_date_mins + ' minutes'" class="p-3 mb-4 flex items-center justify-between rounded-border bg-teal-50 text-teal-900">
                        <div class="flex items-center">
                            <i class="pi pi-chart-line text-2xl mr-4"></i>
                            <span class="ext-lg font-medium">Month to Date</span>
                        </div>
                        <span class="text-lg font-bold">{{ utilsation?.month_to_date_percentage?.toFixed(2) }} %</span>
                    </li>
                    <li v-if="hasBeenSeen && cb.current" class="p-3 mb-4 flex items-center justify-between rounded-border bg-teal-50 text-teal-900">
                        <div class="flex items-center">
                            <i class="pi pi-calendar-times text-2xl mr-4"></i>
                            <span class="ext-lg font-medium">Yesterday</span>
                        </div>
                        <span class="text-lg font-bold">
                            <span v-if="yesterdayMinutes"> {{ yesterdayMinutes.toFormat("hH m") }}M</span>
                        </span>
                    </li>
                    <li v-if="hasBeenSeen && utilsation?.previous_starting_from?.seconds > 0" class="p-3 mb-4 flex items-center justify-between rounded-border bg-teal-50 text-teal-900">
                        <div class="flex items-center">
                            <i class="pi pi-backward text-2xl mr-4"></i>
                            <span class="ext-lg font-medium">Last Run</span>
                        </div>
                        <span class="text-lg font-bold">{{ lastRun }}</span>
                    </li>
                </ul>
            </Panel>

            <AssetJournies v-if="selectedAsset" :selected="selectedAsset" :selected-trip="selectedTrip" />

            <div v-if="hasBeenSeen" v-tooltip="selectedDevice?.timestamp?.toString()" class="last-reported">Tracker last reported information {{ lastDataReceivedAt }}</div>
        </div>
    </div>
</template>

<style lang="scss">
#selected-device-bar {
    margin-bottom: 0;
    padding: 0;
    ul > li {
        font-size: 1.2rem;
        i.pi {
            font-size: 1.2rem;
        }
    }

    .p-button.p-button-danger {
        color: var(--p-surface-300);
        border-width: 2px;
        border-color: var(--p-surface-300);
        .p-button-icon {
            font-size: 1.2rem;
        }
    }

    .p-panel {
        background: var(--p-surface-800);
        .p-panel-header {
            .p-button-text.p-button-secondary {
                margin-right: 1rem;
            }
            i.pi {
                font-size: 1.2rem;
            }
        }
        .p-panel-content {
            padding: 0.5rem 1rem 0 1rem;
        }
    }
}

.voltage-panel {
    .p-panel-header {
        color: var(--p-surface-700) !important;
    }

    .p-panel-header-actions .p-button-text.p-button-secondary {
        color: var(--p-surface-500) !important;
    }
}

.p-breadcrumb {
    padding: 0;
    background: none;
    border: 0;
    border-radius: 0;
}

.off-state {
    font-size: 1.5rem;
    color: #f00;
}

.last-reported {
    padding: 3px 5px 0 5px;
    text-align: center;
}
</style>
