import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 22 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      d: "M8 18.5a2.5 2.5 0 0 1-5 0 2.5 2.5 0 0 1 5 0M21 18.5a2.5 2.5 0 0 1-5 0 2.5 2.5 0 0 1 5 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      d: "m18.5 18.5-2.5-9h-2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#a5a9af",
      d: "M17.568 12.236c-.552 0-1.005-.447-1.005-1s.442-1 .995-1h.01a1 1 0 1 1 0 2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      d: "M16.17 11.99h-2.733L12 13.558H9.405c-.53 0-1.043-.185-1.451-.524l-1.927-1.597a1.75 1.75 0 0 0-1.21-.401l-3.817.2 2.729 2.682s3.291.106 3.281 2.594M5.5 18.5h8.183S14 16 15 15s2.206-1.244 2.206-1.244"
    }, null, -1)
  ])))
}
export default { render: render }