import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 22 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"none\" d=\"M3 17h12\"></path><path fill=\"none\" d=\"M3 21h11\" data-name=\"Path_32\"></path><path fill=\"none\" d=\"M2 17.125V11h5l2 1.75h6V18\"></path><circle cx=\"14.01\" cy=\"18.96\" r=\"2\" fill=\"none\"></circle><circle cx=\"3\" cy=\"19\" r=\"2\" fill=\"none\"></circle><path d=\"m20.526 18.703-1.002-3.637-2.645 2.685z\"></path><path fill=\"none\" d=\"M13 12V9.143L14 8M3 11l1-7h7M11 12 9 4M6 10V8M3 14h1\"></path>", 7)
  ])))
}
export default { render: render }