<script setup lang="ts">
import { ref, toRef } from "vue";
import type { ReportPerDayResult } from "@/types";
import { DateTime, Duration, Interval } from "luxon";
import Button from "primevue/button";
import { saveAs } from "file-saver";

const props = defineProps<{
    results: ReportPerDayResult;
    download: (format: string) => any;
}>();

const menu = ref(null);

const toggleMenu = (event) => {
    menu.value.toggle(event);
};

const overlayMenuItems = ref([
    {
        label: "Export as JSON",
        icon: "pi pi-table",
        command: async () => {
            const dl = toRef(props, "download").value;

            const got = await dl("json");

            const blob = new Blob([JSON.stringify(got)], { type: "application/json;charset=utf-8" });
            saveAs(blob, "utilisation-report.json");
        }
    }
    /*{
        label: 'Export as CSV',
        icon: 'pi pi-tags',
        command: async () => {
            const dl = toRef(props, 'download').value

            const got = await dl("csv")

            const blob = new Blob([JSON.stringify(got)], {type: "text/csv;charset=utf-8"});
            saveAs(blob, "utilisation-report.csv");
        }
    },
    {
        separator: true,
    },
    {
        label: "View JSON Schema",
        icon: 'pi pi-info-circle',
        command: () => {
            visible.value = true;
        }
    }*/
]) as any;

const pluralise = (theInput: number) => {
    // Cater for floats.
    // @ts-ignore
    if ((theInput) => 1 && theInput < 2) {
        return "";
    }

    return "s";
};
const results = toRef(props, "results");

const generationTime = DateTime.fromISO(results.value.reportTime);

const fromDate = DateTime.fromISO(results.value.fromDate);
const untilDate = DateTime.fromISO(results.value.toDate);

const reportingPeriod = Interval.fromDateTimes(fromDate, untilDate);

const content = ref([
    { icon: "pi pi-fw pi-phone", title: "Report Generated", info: generationTime.toLocaleString(DateTime.DATETIME_FULL) },
    { icon: "pi pi-fw pi-map-marker", title: "Report Range", info: `${fromDate.toLocaleString(DateTime.DATE_SHORT)} until ${untilDate.toLocaleString(DateTime.DATE_SHORT)} (AEDT)` },
    {
        icon: "pi pi-fw pi-print",
        title: "Report Coverage",
        info: `${Math.round(reportingPeriod.length("days"))} day${pluralise(reportingPeriod.length("days")!)}/${Math.round(reportingPeriod.length("hours"))} hour${pluralise(reportingPeriod.length("hours")!)}`
    }
]);

const calculatePercentage = (input: number) => {
    return (input / (results.value.daysTotal * 43200)) * 100;
};

const bbzz = `{
  "$id": "https://trackthis.com.au/schemas/report-utilisation.json"
  "$schema": "https://json-schema.org/draft/2019-09/schema#",
  "type": "object",
  "properties": {
    "parameters": {
      "type": "object",
      "properties": {
        "timezone": {
          "type": "string"
        },
        "date_range": {
          "type": "object",
          "properties": {
            "start_time": {
              "type": "date-time"
            },
            "until_time": {
              "type": "date-time"
            },
            "range": {
              "type": "duration"
            }
          },
          "required": [
            "start_time",
            "until_time",
            "range"
          ]
        },
        "target_daily_duration": {
          "type": "string"
        }
      },
      "required": [
        "timezone",
        "date_range",
        "target_daily_duration"
      ]
    },
    "date_breakdown": {
      "type": "array",
      "items": [
        {
          "type": "object",
          "properties": {
            "date": {
              "type": "string"
            },
            "assets": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "asset": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "serial": {
                          "type": "string"
                        }
                      },
                      "required": [
                        "name",
                        "serial"
                      ]
                    },
                    "utilisation": {
                      "type": "number"
                    },
                    "duration": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "asset",
                    "utilisation",
                    "duration"
                  ]
                }
              ]
            },
            "total_utilisation": {
              "type": "string"
            }
          },
          "required": [
            "date",
            "assets",
            "total_utilisation"
          ]
        }
      ]
    },
    "asset_totals": {
      "type": "array",
      "items": [
        {
          "type": "object",
          "properties": {
            "asset": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "serial": {
                  "type": "string"
                }
              },
              "required": [
                "name",
                "serial"
              ]
            },
            "total_utilisation": {
              "type": "string"
            }
          },
          "required": [
            "asset",
            "total_utilisation"
          ]
        }
      ]
    },
    "metadata": {
      "type": "object",
      "properties": {
        "generated_at": {
          "type": "string"
        },
        "report_type": {
          "type": "string"
        }
      },
      "required": [
        "generated_at",
        "report_type"
      ]
    }
  },
  "required": [
    "parameters",
    "date_breakdown",
    "asset_totals",
    "metadata"
  ]
}`;

const visible = ref(false);
</script>
<template>
    <Dialog v-model:visible="visible" modal header="JSON Schema" :style="{ width: '50vw' }">
        <CodeBlock :code="bbzz" lang="json" :highlightjs="true" theme="base16-onedark" />
    </Dialog>
    <div id="printable">
        <div class="flex justify-between flex-wrap card-container">
            <div class="flex items-center justify-center m-2">
                <div class="font-bold text-5xl text-surface-900 dark:text-surface-0 mb-4 the-heading">Asset Utilisation Report</div>
            </div>
            <div class="flex items-center justify-center m-2">
                <Menu ref="menu" :model="overlayMenuItems" :popup="true" style="width: 200px" />
                <Button type="button" label="Export" class="printme right-0" icon="pi pi-angle-down" @click="toggleMenu" style="width: auto" />
            </div>
        </div>

        <div class="flex md:flex-row" :style="{ columnGap: '2rem', rowGap: '2rem' }">
            <div v-for="(item, i) in content" :key="i" class="flex flex-initial grow flex-col justify-center text-center border border-surface py-4 px-6 rounded-border">
                <span class="text-surface-900 dark:text-surface-0 font-bold mb-1">{{ item.title }}</span>
                <span class="text-surface-500 dark:text-surface-300">{{ item.info }}</span>
            </div>
        </div>
        <br />

        <h2 class="font-bold text-3xl m-4">Summary</h2>
        <DataTable sortField="totalPercentage" :sortOrder="-1" showGridlines :value="results.assetSummary" resizableColumns columnResizeMode="fit" scrollable tableStyle="width: 100%">
            <Column field="assetName" header="Name"> </Column>
            <Column field="assetSerial" header="Serial"> </Column>
            <Column header="Total Utilisation">
                <template #body="slotProps"> {{ Duration.fromObject({ seconds: slotProps.data.grandTotalSeconds }).toFormat("hH m") }}M </template>
            </Column>
            <Column header="Utilisation Average" style="min-width: 35%">
                <template #body="slotProps">
                    <ProgressBar :value="calculatePercentage(slotProps.data.grandTotalSeconds)"> {{ calculatePercentage(slotProps.data.grandTotalSeconds).toFixed(1) }}% </ProgressBar>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<style lang="css">
@media print {
    @page {
        size: landscape;
    }

    .text-700 {
        color: #000 !important;
    }

    .text-xl {
        color: #000 !important;
    }

    .p-datatable .p-datatable-thead > tr > th {
        color: #000 !important;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0.3rem !important;
    }

    .p-datatable .p-datatable-tbody > tr {
        color: #000 !important;
    }

    .printme {
        display: none !important;
    }

    body {
        color: #000 !important;
        text-align: center !important;
    }

    .the-heading {
        text-align: center !important;
    }

    #printable {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99999;
        background: #000000;
        height: 100vh;
    }

    .layout-sidebar {
        display: none;
    }
}

.layout-dim .p-datatable-tbody > tr {
    background: #071426 !important;
}

.p-datatable-header {
    background: var(--surface-section) !important;
}

.p-datatable .p-datatable-thead th {
    background: var(--surface-section) !important;
}

.p-progressbar {
    width: 100%;
}
</style>
