<script setup>
import { useLayout } from "@/layout/composables/layout";
import Silhouette from "@/assets/image/silhouette.png";

const { onProfileSidebarToggle } = useLayout();
</script>

<template>
    <!-- todo: remove specificity -->
    <Button type="menu" class="!rounded-full !p-0 !border-none w-full max-w-[38px] h-[38px]" @click="onProfileSidebarToggle">
        <img :src="Silhouette" alt="Profile" style="" class="" />
    </Button>
</template>
